<template>
  <div>
    <topDiv style="width: 98.5vw;height: 3vh"></topDiv>

    <div class="container">
      <div class="left-div">
        <el-row class="tac">
          <el-col :span="12">
            <el-menu
              default-active="2"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              style="width: 200px;">
              <el-menu-item index="1" @click="changeNum(1)">
                <i class="el-icon-user-solid"></i>
                <span slot="title" >个人信息</span>
              </el-menu-item>
              <el-submenu index="2" default-active="2-0" >
                <template slot="title">
                  <i class="el-icon-stopwatch" @click="changeNum(2)"></i>
                  <span @click="changeNum(2)">我的船队</span>
                </template>
                <el-menu-item-group>
<!--                  <el-menu-item index="2-0" @click="changeNum(2)">首页</el-menu-item>-->
                  <el-menu-item index="2-1" @click="changeNum(21)">船队1</el-menu-item>
                  <el-menu-item index="2-2" @click="changeNum(21)">船队2</el-menu-item>
                  <el-menu-item index="2-3" @click="changeNum(21)">船队3</el-menu-item>
                </el-menu-item-group>
              </el-submenu>


              <el-submenu index="3">
                <template slot="title">
                  <i class="el-icon-stopwatch" @click="changeNum(3)"></i>
                  <span @click="changeNum(3)">我的区域</span>
                </template>
                <el-menu-item-group>
<!--                  <el-menu-item index="3-0"  @click="changeNum(3)">首页</el-menu-item>-->
                  <el-menu-item index="3-1"  @click="changeNum(31)">区域1</el-menu-item>
                  <el-menu-item index="3-2"  @click="changeNum(31)">区域2</el-menu-item>
                  <el-menu-item index="3-3"  @click="changeNum(31)">区域3</el-menu-item>
                </el-menu-item-group>
              </el-submenu>
              <el-menu-item index="4" @click="changeNum(4)">
                <i class="el-icon-chat-dot-round"></i>
                <span slot="title" >消息</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>



      <div class="right-div">
        <div v-if="this.num === 1">
          <p style="color: #888888;margin-left: 25px">当前位置：个人中心--个人信息</p>
          <div style="width: 97%; height: 170px; border-radius: 7px; background: #ffffff; margin-left: 25px;">
            <div class="flex-container">
              <div class="block"><el-avatar :size="50" :src="circleUrl" style="margin: 20px"></el-avatar></div>
              <h2 style="color: #888888">{{ this.userName }}</h2>
            </div>
            <div class="flex-container">
            <p style="color: #888888;margin-left: 25px">用户名: </p> <b style="color: #000000;margin-left: 15px">{{ this.userName }}</b>
              <p style="color: #888888;margin-left: 80px">密码 : </p>
              <el-input
                placeholder="******"
                v-model="password"
                :disabled="true"
                style="width: 250px;margin-left: 30px;margin-right: 50px"></el-input>
              <el-button type="info" icon="el-icon-lock" plain>修改密码</el-button>
              <el-button type="info" icon="el-icon-key" plain>忘记密码</el-button>


            </div>
          </div>



          <div style="width: 97%;height: 630px;border-radius: 7px;background: #ffffff; margin-left: 25px; margin-top:30px ">
            <h2 style="color: #888888; margin-left:20px;padding-top: 10px">账户信息</h2>
            <hr style="padding-top: -10px">
            <div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px; margin-left: 20px">姓名：</p>
                  <el-input
                    placeholder="请输入姓名"
                    v-model="allInfo.name"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">手机：</p>
                  <el-input
                    placeholder="请输入手机"
                    v-model="allInfo.telephone"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">邮箱：</p>
                  <el-input
                    placeholder="请输入邮箱"
                    v-model="allInfo.email"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
                <h2 style="color: #888888; margin-left:20px;padding-top: 10px">企业信息</h2>


              <hr style="padding-top: -10px">
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">公司名称：</p>
                  <el-input
                    placeholder="请输入公司名称"
                    v-model="allInfo.companyName"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">公司邮箱：</p>
                  <el-input
                    placeholder="请输入公司邮箱"
                    v-model="allInfo.companyEmail"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">公司电话：</p>
                  <el-input
                    placeholder="请输入公司电话"
                    v-model="allInfo.companyPhone"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">公司网址：</p>
                  <el-input
                    placeholder="请输入公司网址"
                    v-model="allInfo.companyNet"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">邮编：</p>
                  <el-input
                    placeholder="请输入公司邮编"
                    v-model="allInfo.companyYoubian"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">国家/地区：</p>
                  <el-input
                    placeholder="请输入国家/地区"
                    v-model="allInfo.companyCountry"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">省份：</p>
                  <el-input
                    placeholder="请输入省份"
                    v-model="allInfo.companyProvince"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 20px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">城市：</p>
                  <el-input
                    placeholder="请输入城市"
                    v-model="allInfo.companyCity"
                    :disabled="changeInfo === false"
                    style="width: 450px;margin-left: 40px"></el-input>
                </div>
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 40px">&nbsp;<br></p>
                  <el-button type="primary" style="margin-left: 40px" @click="changeMyInfo(true)" plain>修改</el-button>
                  <el-button type="success" style="margin-left: 20px" @click="changeMyInfo(false)" plain>保存</el-button>
                </div>

              </div>
              <div class="flex-container">
                <div>
                  <p style="color: #888888; margin-right: 10px;margin-left: 20px">详细地址：</p>
                  <el-input
                    placeholder="请输入详细地址"
                    v-model="allInfo.companyPosition"
                    :disabled="changeInfo === false"
                    style="width: 940px;margin-left: 20px"></el-input>
                </div>

              </div>

            </div>

          </div>


        </div>

        <div v-if="this.num === 2">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--我的船队</p>
        </div>
        <div v-if="this.num === 3">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--我的区域</p>
        </div>
        <div v-if="this.num === 4">
          <p style="color: #888888;margin-left: 20px">当前位置：个人中心--消息</p>
        </div>
      </div>
    </div>





  </div>

</template>

<script>
import topDiv from "@/components/topDiv";


export default {


  name: "myinfo",
  mounted(){
    // this.getUserName();
  },

  methods:{
    changeNum(number){
      this.num = number;
      this.userName = localStorage.getItem('username');
      console.log("1111111111111111")
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    changeMyInfo(boolVal){
      this.changeInfo = boolVal;
    }

  },
  data(){
    return{
      allInfo:{
        name:null,
        telephone:null,
        email:null,
        companyName:null,
        companyEmail:null,
        companyPhone:null,
        companyNet:null,
        companyYoubian:null,
        companyCountry:null,
        companyProvince:null,
        companyCity:null,
        companyPosition:null,
      },
      num:1,
      userName:null,
      password:null,
      changeInfo:false,
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",

    }

  },

  components: {
    topDiv,
  },

}
</script>

<style scoped>


.flex-container {
  display: flex;
  align-items: center;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh; /* 设置容器的高度为视窗的百分之百 */
}

.left-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 90%;
  background-color: #ffffff;
  margin-top: 30px;
}

.right-div {
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
  height: 100%;
  background-color: #f2f2f2;
}



</style>
